import React, { Component } from 'react';
import AppRouter from './AppRouter';
import { BodyStyle, SiteWrapper } from './App.styled';

// Styling
import { ThemeProvider } from 'styled-components/macro';
import variablesStyling from './theme/variables';
import colorsStyling from './theme/colors';

class App extends Component {
	render() {
		return (
			<ThemeProvider theme={{ ...variablesStyling, ...colorsStyling }}>
				<SiteWrapper>
					<BodyStyle />
					<AppRouter />
				</SiteWrapper>
			</ThemeProvider>
		);
	}
}

export default App;

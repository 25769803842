import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
	background: ${({ theme }) => theme.gray};
`;

export const CenterContainer = styled.div`
	position: absolute;
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const TextInfo = styled.div`
	font-size: ${({ theme }) => theme.typography.large};
	font-weight: ${({ theme }) => theme.weight.medium};
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
	color: ${({ theme }) => theme.white};
`;

export const ErrorInfo = styled.div`
	font-size: ${({ theme }) => theme.typography.medium};
	font-weight: ${({ theme }) => theme.weight.regular};
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
	color: ${({ theme }) => theme.white};
`;

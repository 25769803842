import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
	display: inline-block;
	padding: ${({ theme }) => theme.padding / 2 + 'px'};
	padding-left: ${({ theme }) => theme.padding + 'px'};
	padding-right: ${({ theme }) => theme.padding + 'px'};
	margin-right: ${({ theme }) => theme.margin / 2 + 'px'};
	margin-bottom: ${({ theme }) => theme.margin / 2 + 'px'};
	background: rgb(240, 240, 240);
	transition: background ${({ theme }) => theme.transitionSpeed};
	color: ${({ theme }) => theme.black};
	&:hover {
		background: rgb(200, 200, 200);
		cursor: pointer;
	}
`;

export const Title = styled.div`
	display: inline-block;
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.regular};
	text-transform: capitalize;
`;

export const Amount = styled.div`
	display: inline-block;
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.regular};
`;

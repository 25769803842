import React from 'react';
import { connect } from 'react-refetch';

import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import SingleBookmark from '../../components/SingleBookmark/SingleBookmark';

const BookmarkPage = props => {
	if (props.bookmarkFetch.pending) {
		return <Loading />;
	} else if (props.bookmarkFetch.rejected) {
		return <Error error={JSON.stringify(props.bookmarkFetch.reason)} />;
	} else if (props.bookmarkFetch.fulfilled) {
		return <SingleBookmark bookmarkData={props.bookmarkFetch.value} />;
	}
	return null;
};

export default connect(props => ({
	bookmarkFetch: `http://bookmarks.jonasgrootkormelink.nl/api/api.php?method=bookmark&id=${
		props.bookmarkId
	}`
}))(BookmarkPage);

import { css } from 'styled-components/macro';

export default css`
	html {
		height: 100%;
	}
	body {
		background: white;
		color: black;
		font-family: 'Work Sans', sans-serif;
		padding: 0px;
		margin: 0px;
		font-weight: 100;
		letter-spacing: 1px;
		height: 100%;
	}
	#root {
		height: 100%;
	}
`;

import React from 'react';

import SingleBookmarkSmall from '../../components/SingleBookmarkSmall/SingleBookmarkSmall';
import SingleTagSmall from '../../components/SingleTagSmall/SingleTagSmall';
import SingleNewsSmall from '../../components/SingleNewsSmall/SingleNewsSmall';

import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	BasicInfo,
	Label,
	BookmarksWrapper,
	BookmarksContainer,
	Bookmark,
	TagsWrapper,
	TagsContainer,
	NewsWrapper,
	NewsContainer,
	NewsItem,
	StyledSearch,
	SearchStatus
} from './SearchPage.styled';

class SearchPage extends React.Component {
	constructor(props) {
		super(props);
		this.searchRef = new React.createRef();
		this.state = {
			searchString: '',
			searchResults: { bookmarks: [], tags: [], news: [] },
			resultsLimit: 60
		};
	}

	handleSearch = () => {
		const searchString = this.searchRef.current.value;
		this.setState({
			searchString
		});
		this.getSearch(this.state.searchString, this.state.resultsLimit);
	};

	getSearch = (query, limit) => {
		fetch(
			`http://bookmarks.jonasgrootkormelink.nl/api/api.php?method=search&limit=${limit}&query=${query}`
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					searchResults: {
						bookmarks: response.bookmarks,
						tags: response.tags,
						news: response.news
					}
				});
			});
	};

	render() {
		const searchData = this.state.searchResults;
		return (
			<Wrapper>
				<HeaderWrapper>
					<HeaderContainer>
						<BasicInfo>
							<StyledSearch
								autoFocus
								type="search"
								value={this.state.searchString}
								ref={this.searchRef}
								onChange={this.handleSearch}
								placeholder="Search.."
							/>
						</BasicInfo>
					</HeaderContainer>
				</HeaderWrapper>
				{searchData.bookmarks.length === 0 && (
					<SearchStatus>
						Please start typing for search queries to show up.
					</SearchStatus>
				)}
				<BookmarksWrapper>
					{searchData.bookmarks.length > 0 && (
						<>
							<Label>Bookmarks</Label>
							<BookmarksContainer>
								{searchData.bookmarks.map(bookmarkEntry => (
									<Bookmark key={bookmarkEntry.id}>
										<SingleBookmarkSmall bookmarkData={bookmarkEntry} />
									</Bookmark>
								))}
							</BookmarksContainer>
						</>
					)}
				</BookmarksWrapper>
				<TagsWrapper>
					{searchData.tags.length > 0 && (
						<>
							<Label>Tags</Label>
							<TagsContainer>
								{searchData.tags.map(tagEntry => (
									<SingleTagSmall key={tagEntry.id} tagData={tagEntry} />
								))}
							</TagsContainer>
						</>
					)}
				</TagsWrapper>
				<NewsWrapper>
					{searchData.news.length > 0 && (
						<>
							<Label>News</Label>
							<NewsContainer>
								{searchData.news.map(newsEntry => (
									<NewsItem key={newsEntry.id}>
										<SingleNewsSmall newsData={newsEntry} />
									</NewsItem>
								))}
							</NewsContainer>
						</>
					)}
				</NewsWrapper>
			</Wrapper>
		);
	}
}

export default SearchPage;

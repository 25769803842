import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
	display: flex;
	box-sizing: border-box;
	width: 100%;
	box-shadow: 0px 0px 20px black;
`;

export const HeaderWrapper = styled.div`
	padding: ${({ theme }) => theme.padding + 'px'};
	background: ${({ theme }) => theme.white};
	box-sizing: border-box;
	width: 100%;
`;

export const HeaderContainer = styled.div`
	display: inline-block;
	box-sizing: border-box;
`;

export const MenuContainer = styled.div`
	display: inline-block;
	box-sizing: border-box;
	height: 100%;
	float: right;
	vertical-align: bottom;
	padding: ${({ theme }) => theme.padding + 'px'};
`;

export const MenuItem = styled(NavLink)`
	display:inline-block;
	font-size: ${({ theme }) => theme.typography.medium};
	font-weight: ${({ theme }) => theme.weight.medium};
	color: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding + 'px'};
	text-transform: capitalize;
	text-decoration: none;
	vertical-align: bottom;
	margin-left:${({ theme }) => theme.margin + 'px'};
	&.active{
		color: ${({ theme }) => theme.white};
		background: ${({ theme }) => theme.black};
	}
}`;

export const Title = styled(NavLink)`
	font-size: ${({ theme }) => theme.typography.verylarge};
	font-weight: ${({ theme }) => theme.weight.bold};
	padding: ${({ theme }) => theme.padding + 'px'};
	color: ${({ theme }) => theme.black};
	text-transform: capitalize;
	display: inline-block;
	text-decoration: none;
`;

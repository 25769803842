import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
`;

export const HeaderWrapper = styled.div`
	padding-top: 150px;
	padding-bottom: 100px;
	text-align: center;
	box-sizing: border-box;
	background: ${({ theme }) => theme.gray};
`;

export const HeaderContainer = styled.div`
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	text-align: left;
	max-width: ${({ theme }) => theme.maxWidth};
`;

export const BasicInfo = styled.div`
	display: block;
	margin-bottom: ${({ theme }) => theme.margin * 2 + 'px'};
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.large};
	font-weight: ${({ theme }) => theme.weight.bold};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding + 'px'};
	text-transform: capitalize;
	display: inline-block;
	line-height: 120%;
	margin-bottom: ${({ theme }) => theme.margin + 'px'};
`;

export const Date = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.medium};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding + 'px'};
	display: inline-block;
	margin-bottom: ${({ theme }) => theme.margin + 'px'};
`;

export const BookmarksWrapper = styled.div`
	display: inline-block;
	text-align: left;
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const RandomBookmarkWrapper = styled.div`
	display: inline-block;
	text-align: left;
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const BookmarksContainer = styled.div`
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	width: 100%;
`;

export const Bookmark = styled.div`
	box-sizing: border-box;
	display: inline-block;
	width: 33%;
	padding: ${({ theme }) => theme.padding / 2 + 'px'};

	@media (max-width: ${({ theme }) => theme.breakpointMedium}) {
		width: 50%;
	}
	@media (max-width: ${({ theme }) => theme.breakpointSmall}) {
		width: 100%;
		display: block;
	}
`;

export const TagsWrapper = styled.div`
	display: inline-block;
	text-align: left;
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const TagsContainer = styled.div`
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	width: 100%;
`;

export const Label = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.medium};
	text-transform: uppercase;
	padding-bottom: ${({ theme }) => theme.padding + 'px'};
`;

import React from 'react';
import { Wrapper, Title, Description } from './SingleNewsSmall.styled';

const SingleNewsSmall = props => {
	const { newsData } = props;
	return (
		<Wrapper to={`/news/${newsData.id}`}>
			<Title>{newsData.title}</Title>
			<Description>{newsData.description}</Description>
		</Wrapper>
	);
};

export default SingleNewsSmall;

import React from 'react';
import { connect } from 'react-refetch';

import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import SingleTag from '../../components/SingleTag/SingleTag';

const TagPage = props => {
	if (props.tagFetch.pending) {
		return <Loading />;
	} else if (props.tagFetch.rejected) {
		return <Error error={JSON.stringify(props.tagFetch.reason)} />;
	} else if (props.tagFetch.fulfilled) {
		return <SingleTag tagData={props.tagFetch.value} />;
	}
	return null;
};

export default connect(props => ({
	tagFetch: `http://bookmarks.jonasgrootkormelink.nl/api/api.php?method=tag&id=${
		props.tagId
	}`
}))(TagPage);

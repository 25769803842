import React from 'react';

import RandomBookmark from '../RandomBookmark/RandomBookmark';
import SingleBookmarkSmall from '../SingleBookmarkSmall/SingleBookmarkSmall';
import SingleTagSmall from '../SingleTagSmall/SingleTagSmall';

import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	BasicInfo,
	Title,
	Label,
	RandomBookmarkWrapper,
	BookmarksWrapper,
	BookmarksContainer,
	Bookmark,
	TagsWrapper,
	TagsContainer
} from './Overview.styled';

const Overview = props => {
	const { overviewData } = props;
	return (
		<Wrapper>
			<HeaderWrapper>
				<HeaderContainer>
					<BasicInfo>
						<Title>Overview</Title>
					</BasicInfo>
				</HeaderContainer>
			</HeaderWrapper>
			<RandomBookmarkWrapper>
				<Label>Random bookmark</Label>
				<RandomBookmark bookmarkData={overviewData.random_bookmark} />
			</RandomBookmarkWrapper>
			<BookmarksWrapper>
				<Label>Latest bookmarks</Label>
				<BookmarksContainer>
					{overviewData.bookmarks.map(bookmarkEntry => (
						<Bookmark key={bookmarkEntry.id}>
							<SingleBookmarkSmall bookmarkData={bookmarkEntry} />
						</Bookmark>
					))}
				</BookmarksContainer>
			</BookmarksWrapper>
			<TagsWrapper>
				<Label>Top tags</Label>
				<TagsContainer>
					{overviewData.tags.map(tagEntry => (
						<SingleTagSmall key={tagEntry.id} tagData={tagEntry} />
					))}
				</TagsContainer>
			</TagsWrapper>
		</Wrapper>
	);
};

export default Overview;

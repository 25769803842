import React from 'react';
import { connect } from 'react-refetch';

import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import SingleNews from '../../components/SingleNews/SingleNews';

const NewsPage = props => {
	if (props.newsFetch.pending) {
		return <Loading />;
	} else if (props.newsFetch.rejected) {
		return <Error error={JSON.stringify(props.newsFetch.reason)} />;
	} else if (props.newsFetch.fulfilled) {
		return <SingleNews newsData={props.newsFetch.value} />;
	}
	return null;
};

export default connect(props => ({
	newsFetch: `http://bookmarks.jonasgrootkormelink.nl/api/api.php?method=newsitem&id=${
		props.newsId
	}`
}))(NewsPage);

import React from 'react';
import SingleBookmarkSmall from '../SingleBookmarkSmall/SingleBookmarkSmall';

import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	BasicInfo,
	Title,
	Label,
	BookmarksWrapper,
	BookmarksContainer,
	Bookmark,
	LoadMoreContainer,
	LoadMoreItem
} from './Bookmarks.styled';

const Bookmarks = props => {
	const { bookmarksData, amount } = props;

	return (
		<Wrapper>
			<HeaderWrapper>
				<HeaderContainer>
					<BasicInfo>
						<Title>Browse bookmarks</Title>
					</BasicInfo>
				</HeaderContainer>
			</HeaderWrapper>
			<BookmarksWrapper>
				<Label>Latest bookmarks</Label>
				<BookmarksContainer>
					{bookmarksData.bookmarks.map(bookmarkEntry => (
						<Bookmark key={bookmarkEntry.id}>
							<SingleBookmarkSmall bookmarkData={bookmarkEntry} />
						</Bookmark>
					))}
				</BookmarksContainer>
			</BookmarksWrapper>
			<LoadMoreContainer>
				<LoadMoreItem exact to={`/bookmarks/${parseInt(amount) + 30}`}>
					Load 30 more bookmarks
				</LoadMoreItem>
			</LoadMoreContainer>
		</Wrapper>
	);
};

export default Bookmarks;

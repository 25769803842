import React from 'react';

import {
	Wrapper,
	MetaContainer,
	Title,
	ColorsContainer,
	ColorRGB,
	ColorHEX,
	Screenshot
} from './SingleBookmarkSmall.styled';

const SingleBookmarkSmall = props => {
	const { bookmarkData } = props;
	console.log('bookmarkData', bookmarkData);
	return (
		<Wrapper
			to={`/bookmark/${bookmarkData.id}`}
			params={{}}
			color_red={bookmarkData.r}
			color_green={bookmarkData.g}
			color_blue={bookmarkData.b}>
			<MetaContainer>
				<Title>{bookmarkData.title}</Title>
				<ColorsContainer>
					<ColorRGB
						color_red={bookmarkData.r}
						color_green={bookmarkData.g}
						color_blue={bookmarkData.b}
					/>
					<ColorHEX hex={bookmarkData.hex} />
					<ColorHEX hex={bookmarkData.hex_most_used} />
				</ColorsContainer>
			</MetaContainer>
			<Screenshot src={`/api/images/` + bookmarkData.image} />
		</Wrapper>
	);
};

export default SingleBookmarkSmall;

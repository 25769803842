import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
`;

export const HeaderWrapper = styled.div`
	padding-top: 150px;
	text-align: center;
	box-sizing: border-box;
	background: rgb(
		${props => props.color_red},
		${props => props.color_green},
		${props => props.color_blue}
	);
`;

export const HeaderContainer = styled.div`
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	text-align: left;
	max-width: ${({ theme }) => theme.maxWidth};
`;

export const Screenshot = styled.img`
	top: 0px;
	left: 0px;
	width: 100%;
	display: inline-block;
	max-width: ${({ theme }) => theme.maxWidth};
	box-sizing: border-box;
	margin-bottom: ${({ theme }) => theme.margin * 3 + 'px'};
	box-shadow: 0px 0px 20px ${props => props.hex};
`;

export const BasicInfo = styled.div`
	display: block;
	margin-bottom: ${({ theme }) => theme.margin * 2 + 'px'};
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.large};
	font-weight: ${({ theme }) => theme.weight.bold};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding + 'px'};
	display: inline-block;
	line-height: 120%;
	margin-bottom: ${({ theme }) => theme.margin + 'px'};
`;

export const Date = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.medium};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding + 'px'};
	display: inline-block;
	margin-bottom: ${({ theme }) => theme.margin + 'px'};
`;

export const HyperLink = styled.a`
	font-size: ${({ theme }) => theme.typography.medium};
	font-weight: ${({ theme }) => theme.weight.medium};
	color: ${({ theme }) => theme.white};
	background: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding + 'px'};
	display: inline-block;
	text-decoration: none;
	line-height: 120%;
`;

export const MetaWrapper = styled.div`
	display: inline-block;
	text-align: center;
	background: white;
	padding-top: ${({ theme }) => theme.padding * 2 + 'px'};
	padding-bottom: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const MetaContainer = styled.div`
	position: relative;
	display: inline-block;
	text-align: left;
	max-width: ${({ theme }) => theme.maxWidth};
`;

export const Label = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.medium};
	text-transform: uppercase;
	padding-bottom: ${({ theme }) => theme.padding + 'px'};
`;

export const DescriptionContainer = styled.div`
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	width: 75%;
	padding: ${({ theme }) => theme.padding + 'px'};
	@media (max-width: ${({ theme }) => theme.breakpointSmall}) {
		width: 100%;
		display: block;
	}
`;

export const Description = styled.div`
	font-size: ${({ theme }) => theme.typography.medium};
	font-weight: ${({ theme }) => theme.weight.light};
	line-height: 150%;
`;

export const ColorsContainer = styled.div`
	vertical-align: top;
	box-sizing: border-box;
	display: inline-block;
	width: 25%;
	padding: ${({ theme }) => theme.padding + 'px'};
	@media (max-width: ${({ theme }) => theme.breakpointSmall}) {
		width: 100%;
		display: block;
	}
`;

export const ColorRGB = styled.div`
	margin: ${({ theme }) => theme.margin / 2 + 'px'};
	display: inline-block;
	width: 30px;
	height: 30px;
	${'' /* border-radius: 50%; */}
	background: rgb(
		${props => props.color_red},
		${props => props.color_green},
		${props => props.color_blue}
	);
`;
export const ColorHEX = styled(ColorRGB)`
	background:${props => props.hex};
	)
`;

export const TagsContainer = styled.div`
	box-sizing: border-box;
	display: block;
	vertical-align: top;
	max-width: 100%;
	padding: ${({ theme }) => theme.padding + 'px'};
	margin-top: ${({ theme }) => theme.margin + 'px'};
`;

export const NewsContainer = styled.div`
	box-sizing: border-box;
	display: block;
	vertical-align: top;
	max-width: 100%;
	padding: ${({ theme }) => theme.padding + 'px'};
	margin-top: ${({ theme }) => theme.margin + 'px'};
`;

export const NavigationContainer = styled.div`
	box-sizing: border-box;
	display: block;
	vertical-align: top;
	max-width: 100%;
	padding: ${({ theme }) => theme.padding + 'px'};
	margin-top: ${({ theme }) => theme.margin + 'px'};
`;

export const NavigationItem = styled.div`
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	@media (max-width: ${({ theme }) => theme.breakpointSmall}) {
		width: 100%;
		display: block;
	}
`;

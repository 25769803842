import React from 'react';
import { connect } from 'react-refetch';

import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import Overview from '../../components/Overview/Overview';

const OverviewPage = props => {
	if (props.overviewFetch.pending) {
		return <Loading />;
	} else if (props.overviewFetch.rejected) {
		return <Error error={JSON.stringify(props.overviewFetch.reason)} />;
	} else if (props.overviewFetch.fulfilled) {
		return <Overview overviewData={props.overviewFetch.value} />;
	}
	return null;
};

export default connect(props => ({
	overviewFetch: `http://bookmarks.jonasgrootkormelink.nl/api/api.php?method=overview`
}))(OverviewPage);

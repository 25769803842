import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
`;

export const CenterContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	text-align: center;
	box-sizing: border-box;
	transform: translate(-50%, 0);
`;

export const TextInfo = styled.div`
	font-size: ${({ theme }) => theme.typography.medium};
	font-weight: ${({ theme }) => theme.weight.regular};
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
	color: ${({ theme }) => theme.black};
`;

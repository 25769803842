import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
`;

export const HeaderWrapper = styled.div`
	padding-top: ${({ theme }) => theme.padding * 6 + 'px'};
	padding-bottom: ${({ theme }) => theme.padding * 6 + 'px'};
	text-align: center;
	box-sizing: border-box;
	background: rgb(
		${props => props.color_red},
		${props => props.color_green},
		${props => props.color_blue}
	);
`;

export const HeaderContainer = styled.div`
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	text-align: left;
	max-width: ${({ theme }) => theme.maxWidth};
`;

export const Screenshot = styled.img`
	top: 0px;
	left: 0px;
	width: 100%;
	display: inline-block;
	max-width: ${({ theme }) => theme.maxWidth};
	box-sizing: border-box;
	box-shadow: 0px 0px 20px ${props => props.hex};
`;

export const BasicInfo = styled.div`
	display: block;
	margin-bottom: ${({ theme }) => theme.margin * 2 + 'px'};
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.large};
	font-weight: ${({ theme }) => theme.weight.bold};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding + 'px'};
	display: inline-block;
	line-height: 120%;
	margin-bottom: ${({ theme }) => theme.margin + 'px'};
`;

export const Date = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.medium};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding + 'px'};
	display: inline-block;
	margin-bottom: ${({ theme }) => theme.margin + 'px'};
`;

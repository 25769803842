import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
	display: block;
	text-decoration: none;
	background: rgb(240, 240, 240);
	padding: ${({ theme }) => theme.padding + 'px'};
	transition: background ${({ theme }) => theme.transitionSpeed};
	&:hover {
		background: rgb(200, 200, 200);
		cursor: pointer;
	}
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.medium};
	color: ${({ theme }) => theme.black};
	padding-bottom: ${({ theme }) => theme.padding + 'px'};
`;

export const Description = styled.div`
	line-height: 150%;
	font-size: ${({ theme }) => theme.typography.small};
	font-weight: ${({ theme }) => theme.weight.regular};
	color: ${({ theme }) => theme.black};
`;

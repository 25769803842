import React from 'react';
import { Wrapper, CenterContainer, TextInfo, ErrorInfo } from './Error.styled';

const Error = props => {
	const error = props.error;
	return (
		<Wrapper>
			<CenterContainer>
				<TextInfo>Sorry the following error occurred:</TextInfo>
				<ErrorInfo>{error}</ErrorInfo>
			</CenterContainer>
		</Wrapper>
	);
};

export default Error;

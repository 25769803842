import React from 'react';
import SingleBookmarkSmall from '../SingleBookmarkSmall/SingleBookmarkSmall';

import { convertUnix } from '../../helpers/helpers';
import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	BasicInfo,
	Title,
	Description,
	Date,
	Label,
	RelatedBookmarksWrapper,
	RelatedBookmarksContainer,
	RelatedBookmark
} from './SingleNews.styled';

const SingleNews = props => {
	const { newsData } = props;
	const news = newsData.news;
	const related_bookmarks = newsData.related_bookmarks;
	return (
		<Wrapper>
			<HeaderWrapper>
				<HeaderContainer>
					<BasicInfo>
						<Title>{news.title}</Title>
						<br />
						<Description>{news.description}</Description>
						<br />
						<Date>{convertUnix(news.unix_timestamp)}</Date>
					</BasicInfo>
				</HeaderContainer>
			</HeaderWrapper>
			<RelatedBookmarksWrapper>
				<Label>Related bookmarks({related_bookmarks.length})</Label>
				<RelatedBookmarksContainer>
					{related_bookmarks.map(bookmarkEntry => (
						<RelatedBookmark key={bookmarkEntry.id}>
							<SingleBookmarkSmall bookmarkData={bookmarkEntry} />
						</RelatedBookmark>
					))}
				</RelatedBookmarksContainer>
			</RelatedBookmarksWrapper>
		</Wrapper>
	);
};

export default SingleNews;

import React from 'react';
import SingleNewsSmall from '../SingleNewsSmall/SingleNewsSmall';
import SingleTagSmall from '../SingleTagSmall/SingleTagSmall';
import SingleBookmarkSmall from '../SingleBookmarkSmall/SingleBookmarkSmall';

import { convertUnix } from '../../helpers/helpers';
import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	MetaWrapper,
	MetaContainer,
	DescriptionContainer,
	Label,
	Description,
	ColorsContainer,
	BasicInfo,
	Screenshot,
	Title,
	Date,
	HyperLink,
	ColorRGB,
	ColorHEX,
	TagsContainer,
	NewsContainer,
	NavigationContainer,
	NavigationItem
} from './SingleBookmark.styled';

const SingleBookmark = props => {
	const { bookmarkData } = props;
	const bookmark = bookmarkData.bookmark;
	const prevBookmark = bookmarkData.prev_bookmark;
	const nextBookmark = bookmarkData.next_bookmark;
	const newsItems = bookmarkData.related_news;
	const tagItems = bookmarkData.related_tags;

	console.log(bookmarkData);
	return (
		<Wrapper>
			<HeaderWrapper
				color_red={bookmark.r}
				color_green={bookmark.g}
				color_blue={bookmark.b}>
				<HeaderContainer>
					<BasicInfo>
						<Title>{bookmark.title}</Title>
						<br />
						<Date>{convertUnix(bookmark.unix_timestamp)}</Date>
						<br />
						<HyperLink href={bookmark.url} target="blank">
							{bookmark.url}
						</HyperLink>
					</BasicInfo>
					<Screenshot
						hex={bookmark.hex}
						src={`/api/images/` + bookmark.image}
					/>
				</HeaderContainer>
			</HeaderWrapper>
			<MetaWrapper>
				<MetaContainer>
					{bookmark.description !== '' && (
						<DescriptionContainer>
							<Label> Short Description</Label>
							<Description>{bookmark.description}</Description>
						</DescriptionContainer>
					)}
					<ColorsContainer>
						<Label>Colors</Label>
						<ColorRGB
							color_red={bookmark.r}
							color_green={bookmark.g}
							color_blue={bookmark.b}
						/>
						<ColorHEX hex={bookmark.hex} />
						<ColorHEX hex={bookmark.hex_most_used} />
					</ColorsContainer>
					{tagItems.length > 0 && (
						<TagsContainer>
							<Label>Relevant Related tags({tagItems.length})</Label>
							{tagItems.map(tagEntry => (
								<SingleTagSmall key={tagEntry.id} tagData={tagEntry} />
							))}
						</TagsContainer>
					)}
					<NewsContainer>
						<Label>Related news/mnemonic</Label>
						{newsItems.map(newsEntry => (
							<SingleNewsSmall key={newsEntry.id} newsData={newsEntry} />
						))}
					</NewsContainer>
					<NavigationContainer>
						<Label>Other bookmarks</Label>
						<NavigationItem>
							{Object.entries(prevBookmark).length !== 0 && (
								<SingleBookmarkSmall bookmarkData={prevBookmark} />
							)}
						</NavigationItem>
						<NavigationItem>
							{Object.entries(nextBookmark).length !== 0 && (
								<SingleBookmarkSmall bookmarkData={nextBookmark} />
							)}
						</NavigationItem>
					</NavigationContainer>
				</MetaContainer>
			</MetaWrapper>
		</Wrapper>
	);
};

export default SingleBookmark;

const variables = {
	typography: {
		verysmall: '10px',
		small: '12px',
		medium: '16px',
		large: '24px',
		verylarge: '36px'
	},
	weight: {
		light: 300,
		regular: 400,
		medium: 600,
		bold: 700
	},
	padding: 10,
	margin: 10,
	transitionSpeed: '500ms',
	maxWidth: '1280px',
	breakpointSmall: '520px',
	breakpointMedium: '720px'
};

export default variables;

import React from 'react';
import { Wrapper, Title, Amount } from './SingleTagSmall.styled';

const SingleTagSmall = props => {
	const { tagData } = props;
	return (
		<Wrapper to={`/tag/${tagData.id}`}>
			<Title>
				{tagData.tag}
				<Amount>({tagData.amount})</Amount>
			</Title>
		</Wrapper>
	);
};

export default SingleTagSmall;

import React from 'react';
import { connect } from 'react-refetch';

import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import Bookmarks from '../../components/Bookmarks/Bookmarks';

const BookmarksOverviewPage = props => {
	if (props.bookmarksFetch.pending) {
		return <Loading />;
	} else if (props.bookmarksFetch.rejected) {
		return <Error error={JSON.stringify(props.bookmarksFetch.reason)} />;
	} else if (props.bookmarksFetch.fulfilled) {
		return (
			<Bookmarks
				bookmarksData={props.bookmarksFetch.value}
				amount={props.limit}
			/>
		);
	}
	return null;
};

export default connect(props => ({
	bookmarksFetch: `http://bookmarks.jonasgrootkormelink.nl/api/api.php?method=bookmarks&limit=${props.limit}&sort=DESC`
}))(BookmarksOverviewPage);

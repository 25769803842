import React from 'react';

import { convertUnix } from '../../helpers/helpers';
import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	BasicInfo,
	Screenshot,
	Title,
	Date
} from './RandomBookmark.styled';

const RandomBookmark = props => {
	const { bookmarkData } = props;
	const bookmark = bookmarkData;
	return (
		<Wrapper to={`/bookmark/${bookmark.id}`}>
			<HeaderWrapper
				color_red={bookmark.r}
				color_green={bookmark.g}
				color_blue={bookmark.b}>
				<HeaderContainer>
					<BasicInfo>
						<Title>{bookmark.title}</Title>
						<br />
						<Date>{convertUnix(bookmark.unix_timestamp)}</Date>
					</BasicInfo>
					<Screenshot
						hex={bookmark.hex}
						src={`/api/images/` + bookmark.image}
					/>
				</HeaderContainer>
			</HeaderWrapper>
		</Wrapper>
	);
};

export default RandomBookmark;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
	position: relative;
	display: inline-block;
	background: rgb(
		${props => props.color_red},
		${props => props.color_green},
		${props => props.color_blue}
	);
	box-sizing: border-box;
	width: 100%;
	padding: 5px;
`;

export const MetaContainer = styled.div`
	position: absolute;
	box-sizing: border-box;
	display: inline-block;
	z-index: 1;
	padding: ${({ theme }) => theme.padding + 'px'};
	vertical-align: middle;
`;

export const Title = styled.div`
	vertical-align: middle;
	box-sizing: border-box;
	font-size: ${({ theme }) => theme.typography.medium};
	font-weight: ${({ theme }) => theme.weight.regular};
	color: ${({ theme }) => theme.white};
	background: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding + 'px'};
`;

export const ColorsContainer = styled.div`
	display: inline-block;
	box-sizing: border-box;
	background: ${({ theme }) => theme.white};
	margin-top: ${({ theme }) => theme.padding + 'px'};
	line-height: 0px;
`;

export const ColorRGB = styled.div`
	box-sizing: border-box;
	display: inline-block;
	width: 15px;
	height: 15px;
	background: rgb(
		${props => props.colorR},
		${props => props.colorG},
		${props => props.colorB}
	);
`;
export const ColorHEX = styled(ColorRGB)`
	background:${props => props.hex};
	)
`;

export const Screenshot = styled.img`
	top: 0px;
	left: 0px;
	width: 100%;
	display: block;
	width: 100%;
	box-sizing: border-box;
	transition: opacity ${({ theme }) => theme.transitionSpeed};
	opacity: 0.35;
	&:hover {
		opacity: 1;
		cursor: pointer;
	}
`;

import React from 'react';

import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	Title,
	MenuContainer,
	MenuItem
} from './Header.styled';

const Header = () => {
	return (
		<Wrapper>
			<HeaderWrapper>
				<HeaderContainer>
					<Title to={`/`}>Visual Bookmarks</Title>
				</HeaderContainer>
				<MenuContainer>
					<MenuItem exact to={`/`}>
						Overview
					</MenuItem>
					<MenuItem exact to={`/bookmarks/30`}>
						Browse
					</MenuItem>
					<MenuItem exact to={`/search`}>
						Search
					</MenuItem>
				</MenuContainer>
			</HeaderWrapper>
		</Wrapper>
	);
};

export default Header;

import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';

import Header from './components/Header/Header';

import BookmarkPage from './pages/BookmarkPage/BookmarkPage';
import NewsPage from './pages/NewsPage/NewsPage';
import TagPage from './pages/TagPage/TagPage';

import BookmarksOverviewPage from './pages/BookmarksOverviewPage/BookmarksOverviewPage';
import OverviewPage from './pages/OverviewPage/OverviewPage';
import SearchPage from './pages/SearchPage/SearchPage';

const AppRouter = props => {
	return (
		<BrowserRouter>
			<Header />
			<Switch>
				<Route
					exact
					path="/"
					render={() => {
						return <OverviewPage />;
					}}
				/>
				<Route
					exact
					path="/bookmarks/:amount"
					render={props => {
						const { amount } = props.match.params;
						return <BookmarksOverviewPage limit={amount} />;
					}}
				/>
				<Route
					exact
					path="/search"
					render={props => {
						return <SearchPage />;
					}}
				/>
				<Route
					exact
					path="/bookmark/:bookmarkId"
					render={props => {
						const { bookmarkId } = props.match.params;
						return <BookmarkPage bookmarkId={bookmarkId} />;
					}}
				/>
				<Route
					exact
					path="/tag/:tagId"
					render={props => {
						const { tagId } = props.match.params;
						return <TagPage tagId={tagId} />;
					}}
				/>
				<Route
					exact
					path="/news/:newsId"
					render={props => {
						const { newsId } = props.match.params;
						return <NewsPage newsId={newsId} />;
					}}
				/>
			</Switch>
		</BrowserRouter>
	);
};

export default AppRouter;

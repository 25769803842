import React from 'react';
import loadingGif from '../../images/loading.gif';
import { Wrapper, CenterContainer, TextInfo } from './Loading.styled';

const Loading = () => {
	return (
		<Wrapper>
			<CenterContainer>
				<img src={loadingGif} alt="loading indicator" />
				<TextInfo>Loading content...</TextInfo>
			</CenterContainer>
		</Wrapper>
	);
};

export default Loading;

import React from 'react';
import SingleBookmarkSmall from '../SingleBookmarkSmall/SingleBookmarkSmall';

import {
	Wrapper,
	HeaderWrapper,
	HeaderContainer,
	BasicInfo,
	Title,
	Amount,
	Label,
	RelatedBookmarksWrapper,
	RelatedBookmarksContainer,
	RelatedBookmark
} from './SingleTag.styled';

const SingleTag = props => {
	const { tagData } = props;
	const tag = tagData.tag;
	const related_bookmarks = tagData.related_bookmarks;
	return (
		<Wrapper>
			<HeaderWrapper>
				<HeaderContainer>
					<BasicInfo>
						<Title>{tag.tag}</Title>
						<br />
						<Amount>{tag.amount} bookmarks</Amount>
					</BasicInfo>
				</HeaderContainer>
			</HeaderWrapper>
			<RelatedBookmarksWrapper>
				<Label>Related bookmarks({tag.amount})</Label>
				<RelatedBookmarksContainer>
					{related_bookmarks.map(bookmarkEntry => (
						<RelatedBookmark key={bookmarkEntry.id}>
							<SingleBookmarkSmall bookmarkData={bookmarkEntry} />
						</RelatedBookmark>
					))}
				</RelatedBookmarksContainer>
			</RelatedBookmarksWrapper>
		</Wrapper>
	);
};

export default SingleTag;
